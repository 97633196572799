import { curry, includes } from 'rambdax'

const onClickKeys = ['Enter', ' ']

export function handleKeys (keys, callback) {
    return function onKeys (event) {
        const key = event.key

        if (includes(keys, key)) {
            callback(event)
        }

        return false
    }
}

export const a11yKeys = curry(handleKeys)(onClickKeys)
