<script>
    import { onDestroy, onMount } from 'svelte'
    import { messages } from '@landing/lib/messaging'

    import { handleKeys } from '@maps/lib/keyboard'

    export let active = false
    export let id

    function onOpen (event) {
        if (event.id === id) {
            active = true
        }
        return false
    }

    function onClose (event) {
        active = false
        return false
    }

    onMount(() => {
        messages.on('modal:open', onOpen)
    })

    onDestroy(() => {
        messages.off('modal:open', onOpen)
    })
</script>

<svelte:window on:keydown={handleKeys(['Escape'], onClose)} />

<div id="{$$props.id}"
     class="modal maps-modal {$$props.class || ''}"
     aria-labelledby="modal-title"
     aria-modal="true"
     role="dialog"
     class:active={active}
     >
    <div class="modal-overlay" aria-hidden="true" on:click={onClose}></div>
    <div class="modal-container">
        <div class="modal-header">
            <h2 id="modal-title" class="modal-title">
                <slot name="header">
                    Treebuddy
                </slot>
            </h2>
            <a href="#close"
               class="btn btn-yellow btn-clear"
               aria-label="Close"
               on:click|preventDefault|stopPropagation={onClose}>
            </a>
        </div>
        <slot />
    </div>
</div>
